export const environment = {
  envName: 'test',
  apiUrl: window.location.origin,
  redirectUri: 'https://mijn.test.dias.nl',
  postLogoutRedirectUri: 'https://mijn.test.dias.nl',
  domain: 'dias.nl',
  GoogleAnalyticsId: '',
  production: false,
  apiApplicationId: '39fff5a5-63fb-4802-a03f-de07512ad285',
  clientId: 'ca8ea638-272a-4602-9b02-41f01f17b2ef'

};